@charset "UTF-8";

/* Jektify
========================================================*/

/* Import fonts by Google Fonts - https://fonts.google.com/ */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');


/*  Jektify Style
----------------------------------------------------------

.jektify */

.jektify{

  /* Jektify Variables */
  $jektify__background-color--dark: #282828;
  $jektify__background-color--white: #FFF;
  $jektify__font-family--primary: 'Lato', sans-serif;

  *{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  font-family: $jektify__font-family--primary;
  border-radius: 0px;
  overflow: hidden;
  max-width: 980px;
  margin: 30px auto;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 10px 56px -6px black;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &:hover{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  /* .jektify--dark */
  &--dark{
    background-color: $jektify__background-color--dark;
    border: 1px solid #131313;
    text-decoration: none !important;
    &:hover{
      opacity: 0.99;
      text-decoration: none !important;
    }
  }

  /* .jektify--white */
  &--white{
    background-color: $jektify__background-color--white;
    border: 1px solid #adadad;
    text-decoration: none !important;
  }


  /* .jektify__header */
  &__header{
      padding: 20px;
      position: relative;
      font-family: 'Lato', sans-serif;
      border-bottom: solid 1px transparent;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 2.2rem !important;

      /* .jektify__header--dark */
      &--dark{
        background-color: $jektify__background-color--dark;
        text-decoration: none !important;
      }

      /* .jektify__header--white */
      &--white{
        background-color: $jektify__background-color--white;
        text-decoration: none !important;
      }

  }


  /* .jektify__brand */
  &__brand{
    text-decoration: none !important;
    margin-right: 17px;
    padding-bottom: 4px;
    cursor: pointer;
    font-size: 0.81em;
    font-weight: 300;
    letter-spacing: 0.15em;
    -webkit-transition: all .45s;
    -o-transition: all .45s;
    transition: all .45s;

    &:hover{
      text-decoration: none !important;
      opacity: 0.87;
    }

    /* .jektify__brand--dark */
    &--dark{
      border-bottom: 1px solid #1ed760;
      background-color: $jektify__background-color--dark;
      color: #1ed760;
      &:hover{
        color: #fff;
        border-bottom: 1px solid #fff;
      }
    }


    /* .jektify__brand--white */
    &--white{
      background-color: $jektify__background-color--white;
      font-weight: 600;
      color: #282828;
      border-bottom: 1px solid #282828;
      &:hover{
        color: #1ed760;
        border-bottom: 1px solid #1ed760;
      }
    }

  }

  /* .jektify__year */
  &__year{
      font-style: normal;
      font-size: 0.81em;
      font-family: inherit;
      font-weight: 300;

    /* .jektify__year--dark */
    &--dark{
      background-color: $jektify__background-color--dark;
      color: #fff;
    }

    /* .jektify__year--white */
    &--white{
      background-color: $jektify__background-color--white;
      font-weight: 600;
      color: #282828;
    }

  }

  /* .jektify__button */
  &__button{
      font-size: 2.5em;
      float: right;
      -webkit-transition: all .45s;
      -o-transition: all .45s;
      transition: all .45s;
      right: 20px;
      cursor: pointer;
      font-weight: 300;

      &:hover{
        opacity: 0.87;
      }

      /* .jektify__button--dark */
      &--dark{
        color: #1ed760;
        &:hover{
          color: #fff;
        }
      }

    /* .jektify__button--white */
    &--white{
      color: #282828;
      &:hover{
        color: #1ed760;
      }
    }

    /* .jektify__button--open */
    &--open{
      transform: rotate(315deg);
    }

    /* .jektify__button--closed */
    &--closed{
      transform: rotate(0deg);
    }
  }

  /* .jektify__title */
  &__title{
    font-family: inherit;
    letter-spacing: 0.17em;
    text-align: center;
    font-size: 1.9em;

   /* .jektify__title--dark */
    &--dark{
      background-color: $jektify__background-color--dark;
      color: #fff;
    }

    /* .jektify__title--white */
    &--white{
      background-color: $jektify__background-color--white;
    }

  }

  /* .jektify__body */
  &__body{
    padding: 0px;
    -webkit-margin-start: 0px;
    -moz-margin-start: 0px;
    border-top: solid 1px rgba(0, 0, 0, 0.3);

    /* .jektify__body--dark */
    &--dark{ }

    /* .jektify__body--white */
    &--white{ }
  }

  /* .jektify__user */
   &__user{
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -ms-flex-wrap: wrap;
     flex-wrap: wrap;
     margin-right: -15px;
     margin-left: -15px;
     -webkit-box-pack: end !important;
     -ms-flex-pack: end !important;
     justify-content: flex-end !important;
     line-height: 2.2rem !important;
     margin: 20px 0px 20px 0px;

      /* .jektify__user--dark */
     &--dark{ }

      /* .jektify__user--white */
     &--white{
       color: #282828;
     }

     /* .jektify__user-link */
      &-link{
        border-bottom: 1px solid transparent;
        margin-left: 40px;
        height: 20px;
        background-image: url(https://raw.githubusercontent.com/jektify/jektify/master/assets/vendor/jektify/imgs/spotify_logo.png);
        background-repeat: no-repeat;
        background-size: 20px;
        margin-right: 20px;

        &:hover{
          text-decoration: none;
        }
         /* .jektify__user-link--dark */
        &--dark{ }

         /* .jektify__user-link--white */
        &--white{ }
      }

     /* .jektify__user-text */
      &-text{
        font-size: 0.8em;
        letter-spacing: 0.1em;
        border-bottom: 1px solid transparent;
        -webkit-transition: all .45s;
        -o-transition: all .45s;
        transition: all .45s;
        margin-left: 30px;
        margin-top: -12px;
        float: left;

        &:hover{
          opacity: 0.87;
        }

        /* .jektify__user-text--dark */
        &--dark{
            border-bottom: 1px solid #1ed760;
            color: #1ed760;

        }

        /* .jektify__user-text--white  */
        &--white{
          border-bottom: 1px solid #282828;
          color: #282828;
          &:hover{
            color: #1ed760;
            border-bottom: 1px solid #1ed760;
          }
        }
      }
   }

  /* .jektify__description */
  &__description{
    font-family: inherit;
    letter-spacing: 0.14em;
    line-height: 1.9em;
    font-weight: 300;
    padding: 0px 15px;
    text-align: center;
    font-size: 1.1em;

    /* .jektify__content--dark */
    &--dark{
      background-color: $jektify__background-color--dark;
      color: #fff;
    }

   /* .jektify__content--white */
    &--white{
      background-color: $jektify__background-color--white;
      color: #282828;
    }

  }

 /* .jektify__tracklist */
  &__tracklist{
    height: auto;
    border-radius: 10px;
    padding: 8px;
    margin: 15px;
    border: 1px solid lighten(#1ed760, 1);

    /* .jektify__tracklist--dark */
    &--dark{
      background-color: $jektify__background-color--dark;
    }

    /* .jektify__tracklist--white */
    &--white{
      background-color: $jektify__background-color--white;
      border: 1px solid lighten(#282828, 1);
    }

  }

  /* .jektify__track */
  &__track{

    /* .jektify__track--dark */
    &--dark{
      background-color: $jektify__background-color--dark;
    }

    /* .jektify__track--white */
    &--white{
      background-color: $jektify__background-color--white;
    }

  }


} /* END - .jektify */
